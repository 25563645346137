import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import logo from "../images/logo-concrete-content.svg";
import { Link } from "gatsby";

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Datenschutz | concrete content"
      />
      <section className="max-w-2xl p-4 mx-auto text-sm">
        <div className="text-center">
          <Link className="no-underline px-4" to="/">
            <img
              alt="Concrete Content – Verlag & Kommunikation"
              className="block w-1/4 mx-auto mb-20 hover:opacity-80"
              src={logo}
            />
          </Link>
        </div>
        <h1 className="text-2xl font-bold mb-8">Datenschutzerklärung</h1>
        <p>
          Diese Datenschutzerklärung klärt über die Art, den Umfang und Zwecke
          der Erhebung und Verwendung von personenbezogenen Daten der Besucher,
          Besteller und Nutzer (nachfolgend zusammenfassend bezeichnet als
          „Nutzer“) durch die concrete content UG (hb), Vossenbergweg 13b, 46514
          Schermbeck (nachfolgend „Anbieter“) als datenschutzrechtlich
          verantwortliche Stelle auf. Im Hinblick auf die verwendeten
          Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“
          verweisen wir auf die Definitionen im Art. 4 der
          Datenschutzgrundverordnung (DSGVO).
          <br />
           Verantwortlicher: concrete content UG (hb) Vossenbergweg 13b, 46514
          Schermbeck, Handelsregister: Amtsgericht Duisburg HRB 34645,
          Geschäftsführung: Holger Kotzan, Telefon 02853-9569480, E-Mail:
          holger.kotzan@concrete-content.de.
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">
          § 1 Verwendete Begrifflichkeiten
        </h2>
        <p>
          Personenbezogene Daten sind alle Informationen, die sich auf eine
          identifizierte oder identifizierbare natürliche Person (im Folgenden
          „betroffene Person“) beziehen; als identifizierbar wird eine
          natürliche Person angesehen, die direkt oder indirekt, insbesondere
          mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
          Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie)
          oder zu einem oder mehreren besonderen Merkmalen identifiziert werden
          kann, die Ausdruck der physischen, physiologischen, genetischen,
          psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
          dieser natürlichen Person sind. <br />
          Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren
          ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang
          mit personenbezogenen Daten. Der Begriff reicht weit und umfasst
          praktisch jeden Umgang mit Daten.
          <br />
           Als Verantwortlicher wird die natürliche oder juristische Person,
          Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten entscheidet, bezeichnet.
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">
          § 2 Maßgebliche Rechtsgrundlagen
        </h2>
        <p>
          Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
          unserer Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der
          Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die
          Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1
          lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung zur
          Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen
          sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die
          Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen
          Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die
          Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten
          Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, dass
          lebenswichtige Interessen der betroffenen Person oder einer anderen
          natürlichen Person eine Verarbeitung personenbezogener Daten
          erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
          Rechtsgrundlage.
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">
          § 3 Änderungen und Aktualisierungen der Datenschutzerklärung
        </h2>
        <p>
            Wir bitten Sie sich regelmäßig über den Inhalt unserer
          Datenschutzerklärung zu informieren. Wir passen die
          Datenschutzerklärung an, sobald die Änderungen der von uns
          durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
          informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
          Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
          Benachrichtigung erforderlich wird.
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">
          § 4 Sicherheitsmaßnahmen
        </h2>
        <p>
            Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung
          des Stands der Technik, der Implementierungskosten und der Art, des
          Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
          unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos
          für die Rechte und Freiheiten natürlicher Personen, geeignete
          technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten. Zu den Maßnahmen gehören
          insbesondere die Sicherung der Vertraulichkeit, Integrität und
          Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs zu den
          Daten, als auch des sie betreffenden Zugriffs, der Eingabe,
          Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des
          Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
          Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der
          Daten gewährleisten. Ferner berücksichtigen wir den Schutz
          personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von
          Hardware, Software sowie Verfahren, entsprechend dem Prinzip des
          Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche
          Voreinstellungen berücksichtigt (Art. 25 DSGVO).
          <br />
           Zu den Sicherheitsmaßnahmen gehört insbesondere die verschlüsselte
          Übertragung von Daten zwischen Ihrem Browser und unserem Server.
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">
          § 4 Sicherheitsmaßnahmen
        </h2>
        <p>
            Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung
          des Stands der Technik, der Implementierungskosten und der Art, des
          Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
          unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos
          für die Rechte und Freiheiten natürlicher Personen, geeignete
          technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten. Zu den Maßnahmen gehören
          insbesondere die Sicherung der Vertraulichkeit, Integrität und
          Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs zu den
          Daten, als auch des sie betreffenden Zugriffs, der Eingabe,
          Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des
          Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
          Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der
          Daten gewährleisten. Ferner berücksichtigen wir den Schutz
          personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von
          Hardware, Software sowie Verfahren, entsprechend dem Prinzip des
          Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche
          Voreinstellungen berücksichtigt (Art. 25 DSGVO).
          <br />  Zu den Sicherheitsmaßnahmen gehört insbesondere die
          verschlüsselte Übertragung von Daten zwischen Ihrem Browser und
          unserem Server.
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">
          § 5 Erhebung von Zugriffsdaten
        </h2>
        <p>
          Der Anbieter erhebt Daten über jeden Zugriff auf das Onlineangebot (so
          genannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der
          abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene
          Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst
          Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
          besuchte Seite), IP-Adresse und der anfragende Provider.
          <br />
           Der Anbieter verwendet die Protokolldaten ohne Zuordnung zur Person
          des Nutzers oder sonstiger Profilerstellung entsprechend den
          gesetzlichen Bestimmungen nur für statistische Auswertungen zum Zweck
          des Betriebs, der Sicherheit und der Optimierung des Onlineangebotes.
          Der Anbieter behält sich jedoch vor, die Protokolldaten nachträglich
          zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte
          Verdacht einer rechtswidrigen Nutzung besteht.
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">
          § 6 Erhebung und Nutzung personenbezogener Daten
        </h2>
        <p>
          Personenbezogene Daten werden vom Anbieter nur dann erhoben und
          genutzt, wenn dies gesetzlich erlaubt ist oder die Nutzer in die
          Datenerhebung einwilligen. In der Regel ist es für die Nutzer bei der
          Nutzung des Dienstes erkennbar, welche Daten gespeichert werden, zum
          Beispiel Name, E-Mail-Adresse und Nachricht bei der Nutzung des
          Bestellformulars.
          <br />
          Die zum Zwecke der Bestellung von Waren angegebenen persönlichen Daten
          (zum Beispiel Name, E-Mail-Adresse, Anschrift, Zahlungsdaten) werden
          vom Verkäufer zur Erfüllung und Abwicklung des Vertrags verwendet.
          Diese Daten werden vertraulich behandelt, verschlüsselt übertragen und
          nicht an Dritte weitergegeben, die nicht am Bestell-, Auslieferungs-
          und Zahlungsvorgang beteiligt sind. Bei der Kontaktaufnahme mit dem
          Anbieter (per Kontaktformular oder E-Mail) werden die Angaben des
          Nutzers zwecks Bearbeitung der Anfrage sowie für den Fall, dass
          Anschlussfragen entstehen, gespeichert. <br />
           Der Anbieter hat organisatorische, vertragliche und technische
          Sicherheitsmaßnahmen getroffen, um sicher zu stellen, dass die
          Vorschriften der Datenschutzgesetze eingehalten werden und zufällige
          oder vorsätzliche Manipulationen, Verlust, Zerstörung oder der Zugriff
          unberechtigter Personen verhindert werden. 
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">
          § 7 Datenweiterleitung an Dritte
        </h2>
        <p>
          Die Daten der Nutzer werden nur dann an Dritte weiter geleitet, wenn
          dies gesetzlich erlaubt ist oder ein Nutzer in die Weiterleitung
          eingewilligt hat.
          <br />
          Das ist zum Beispiel der Fall wenn die Weiterleitung der Daten der
          Erfüllung vertraglicher Verpflichtungen gegenüber dem Nutzer dient und
          die Postadresse nach einer Shopbestellung an ein Speditionsunternehmen
          übergeben wird. Dies ist auch der Fall, wenn die Daten von Behörden
          wie Strafverfolgungsbehörden angefordert werden.
          <br />
          Die personenbezogenen Daten der Nutzer werden keineswegs an Dritte zu
          Werbezwecken oder zwecks Erstellung von Nutzerprofilen verkauft oder
          weitergeleitet.
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">§ 8 Cookies</h2>
        <p>
          Der Anbieter setzt im Rahmen seines Onlineangebotes „Cookies“ ein.
          Cookies sind kleine Dateien, die auf dem Computer der Nutzer abgelegt
          werden und Informationen für Anbieter speichern können. Temporäre
          Cookies werden nach dem Schließen des Browsers gelöscht, permanente
          Cookies bleiben für einen vorgegebenen Zeitraum erhalten und können
          beim erneuten Aufruf des Onlineangebotes die gespeicherten
          Informationen zur Verfügung stellen. <br />
          Cookies werden zum einen eingesetzt um die Nutzung des Dienstes zu
          erleichtern. So speichert ein Cookie den Warenkorbstatus eines
          Nutzers.
          <br />
          Der Nutzer kann auf den Einsatz der Cookies Einfluss nehmen. Die
          meisten Browser verfügen über eine Option mit der das Speichern von
          Cookies eingeschränkt oder komplett verhindert wird. Der Anbieter
          bemüht sich das Onlineangebot so zu gestalten, dass der Einsatz von
          Cookies nicht notwendig ist. Allerdings wird darauf hingewiesen, dass
          die Nutzung und insbesondere der Nutzungskomfort ohne Cookies
          eingeschränkt werden. {" "}
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">§ 9 Matomo-Statistiken</h2>
        <p>
          Der Anbieter benutzt Matomo (vorher Piwik), eine Open-Source-Software
          zur statistischen Auswertung der Nutzerzugriffe. Matomo verwendet
          Cookies, die auf dem Computer der Nutzer gespeichert werden und die
          eine Analyse der Benutzung der Website durch die Nutzer ermöglichen.
          Die durch den Cookie erzeugten Informationen über die Benutzung dieses
          Angebotes werden auf dem Server des Anbieters in Deutschland
          gespeichert.
          <br />
           Die IP-Adresse wird sofort nach der Verarbeitung und vor deren
          Speicherung anonymisiert. Nutzer können die Installation der Cookies
          durch eine entsprechende Einstellung Ihrer Browser-Software („Do not
          track“) verhindern. 
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">
          § 10 Rechte der betroffenen Personen
        </h2>
        <p>
          Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
          betreffende Daten verarbeitet werden und auf Auskunft über diese Daten
          sowie auf weitere Informationen und Kopie der Daten entsprechend Art.
          15 DSGVO.
          <br />
           Sie haben entsprechend. Art. 16 DSGVO das Recht, die
          Vervollständigung der Sie betreffenden Daten oder die Berichtigung der
          Sie betreffenden unrichtigen Daten zu verlangen.
          <br />
           Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass
          betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach
          Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der
          Daten zu verlangen.
          <br />
           Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten,
          die Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu
          erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
          <br />
           Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei
          der zuständigen Aufsichtsbehörde einzureichen.
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">§ 11 Widerruf</h2>
        <p>
          Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO
          mit Wirkung für die Zukunft zu widerrufen.
          <br />
           Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach
          Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der Widerspruch
          kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung
          erfolgen
          <br />
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">§ 12 Löschung von Daten</h2>
        <p>
          Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18
          DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht
          im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden
          die bei uns gespeicherten Daten gelöscht, sobald sie für ihre
          Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
          gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten
          nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
          Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h.,
          die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das
          gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen
          aufbewahrt werden müssen.
          <br />
           Nach gesetzlichen Vorgaben erfolgt die Aufbewahrung insbesondere für
          6 Jahre gemäß § 257 Abs. 1 HGB (Handelsbücher, Inventare,
          Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe, Buchungsbelege,
          etc.) sowie für 10 Jahre gemäß § 147 Abs. 1 AO (Bücher,
          Aufzeichnungen, Lageberichte, Buchungsbelege, Handels- und
          Geschäftsbriefe, für Besteuerung relevante Unterlagen, etc.
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">
          § 13 Kontaktaufnahme und Kundenservice
        </h2>
        <p>
          Bei der Kontaktaufnahme mit uns (per Kontaktformular oder E-Mail)
          werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und
          deren Abwicklung gem. Art. 6 Abs. 1 lit. b) DSGVO verarbeitet. Die
          Angaben der Nutzer können in unserem Customer-Relationship-Management
          System („CRM System“) oder vergleichbarer Anfragenorganisation
          gespeichert werden.
          <br />
           Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind.
          Wir überprüfen die Erforderlichkeit alle zwei Jahre; Anfragen von
          Kunden, die über ein Kundenkonto verfügen, speichern wir dauerhaft und
          verweisen zur Löschung auf die Angaben zum Kundenkonto. Ferner gelten
          die gesetzlichen Archivierungspflichten
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">
          § 14 Kommunikation via Post, E-Mail oder Telefon
        </h2>
        <p>
          Wir nutzen für die Geschäftsabwicklung und für Marketingzwecke
          Fernkommunikationsmittel, wie z.B. Post, Telefon oder E-Mail. Dabei
          verarbeiten wir Bestandsdaten, Adress- und Kontaktdaten sowie
          Vertragsdaten von Kunden, Teilnehmern, Interessenten und
          Kommunikationspartner.
          <br />
           Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a, Art.
          7 DSGVO, Art. 6 Abs. 1 lit. f DSGVO in Verbindung mit gesetzlichen
          Vorgaben für werbliche Kommunikationen. Die Kontaktaufnahme erfolgt
          nur mit Einwilligung der Kontaktpartner oder im Rahmen der
          gesetzlichen Erlaubnisse und die verarbeiteten Daten werden gelöscht,
          sobald sie nicht erforderlich sind und ansonsten mit Widerspruch/
          Widerruf oder Wegfall der Berechtigungsgrundlagen oder gesetzlicher
          Archivierungspflichten.
          <br />
           Wir versenden Newsletter, E-Mails und weitere elektronische
          Benachrichtigungen mit werblichen Informationen (nachfolgend
          „Newsletter“) nur mit der Einwilligung der Empfänger oder einer
          gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum
          Newsletter dessen Inhalte konkret umschrieben werden, sind sie für die
          Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten unsere
          Newsletter Informationen zu unseren Produkten, Angeboten, Aktionen und
          unserem Unternehmen.
          <br />
           Soweit wir einen Versanddienstleister einsetzen, kann der
          Versanddienstleister nach eigenen Informationen diese Daten in
          pseudonymer Form, d.h. ohne Zuordnung zu einem Nutzer, zur Optimierung
          oder Verbesserung der eigenen Services nutzen, z. B. zur technischen
          Optimierung des Versands und der Darstellung der Newsletter oder für
          statistische Zwecke, um zu bestimmen aus welchen Ländern die Empfänger
          kommen, verwenden. Der Versanddienstleister nutzt die Daten unserer
          Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben oder
          an Dritte weiterzugeben.
          <br />
           Newsletterempfänger können den Empfang unseres Newsletters jederzeit
          kündigen, d.h. Ihre Einwilligungen widerrufen. Einen Link zur
          Kündigung des Newsletters finden sie am Ende eines jeden Newsletters.
          Damit erlöschen gleichzeitig ihre Einwilligungen in die
          Erfolgsmessung. Ein getrennter Widerruf der Erfolgsmessung ist leider
          nicht möglich, in diesem Fall muss das gesamte Newsletterabonnement
          gekündigt werden. Mit der Abmeldung von Newsletter, werden die
          personenbezogenen Daten gelöscht, es sei denn deren Aufbewahrung ist
          rechtlich geboten oder gerechtfertigt, wobei deren Verarbeitung in
          diesem Fall nur auf diese Ausnahmezwecke beschränkt wird. Wir können
          insbesondere die ausgetragenen E-Mailadressen bis zu drei Jahren auf
          Grundlage unserer berechtigten Interessen speichern bevor wir sie für
          Zwecke des Newsletterversandes löschen, um eine ehemals gegebene
          Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird
          auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein
          individueller Löschungsantrag ist jederzeit möglich, sofern zugleich
          das ehemalige Bestehen einer Einwilligung bestätigt wird.
        </p>

        <h2 className="text-lg font-bold mt-4 mb-2">
          § 15 Einbindung von Diensten und Inhalten Dritter
        </h2>
        <p>
          Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
          berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
          und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art.
          6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von
          Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos
          oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als
          “Inhalte”). Dies setzt immer voraus, dass die Drittanbieter dieser
          Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne die
          IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
          IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich.
          Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige
          Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte
          verwenden. Drittanbieter können ferner so genannte Pixel-Tags
          (unsichtbare Grafiken, auch als „Web Beacons“ bezeichnet) für
          statistische oder Marketingzwecke verwenden. Durch die „Pixel-Tags“
          können Informationen, wie der Besucherverkehr auf den Seiten dieser
          Website ausgewertet werden. Die pseudonymen Informationen können
          ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und
          unter anderem technische Informationen zum Browser und Betriebssystem,
          verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung
          unseres Onlineangebotes enthalten, als auch mit solchen Informationen
          aus anderen Quellen verbunden werden können.
          <br />
           Die nachfolgende Darstellung bietet eine Übersicht von Drittanbietern
          sowie ihrer Inhalte, nebst Links zu deren Datenschutzerklärungen,
          welche weitere Hinweise zur Verarbeitung von Daten und, z.T. bereits
          hier genannt, Widerspruchsmöglichkeiten (sog. Opt-Out) enthalten.
          <br />
           Externe Schriftarten von Google, LLC., https://www.google.com/fonts
          („Google Fonts“). Die Einbindung der Google Fonts erfolgt durch einen
          Serveraufruf bei Google (in der Regel in den USA).
          Datenschutzerklärung: https://policies.google.com/privacy, Opt-Out:
          https://adssettings.google.com/authenticated. Landkarten des Dienstes
          „Google Maps“ des Drittanbieters Google LLC, 1600 Amphitheatre
          Parkway, Mountain View, CA 94043, USA, gestellt. Datenschutzerklärung:
          https://www.google.com/policies/privacy/, Opt-Out:
          https://www.google.com/settings/ads/. Videos der Plattform „YouTube“
          des Dritt-Anbieters Google Inc., 1600 Amphitheatre Parkway, Mountain
          View, CA 94043, USA. Datenschutzerklärung:
          https://www.google.com/policies/privacy/, Opt-Out:
          https://www.google.com/settings/ads/.
        </p>
        <h2 className="text-lg font-bold mt-4 mb-2">
          § 16 Änderungen der Datenschutzerklärung
        </h2>
        <p>
          Der Anbieter behält sich vor, die Datenschutzerklärung zu ändern, um
          sie an die geänderte aktuelle Rechtslage oder bei Änderungen des
          Dienstes sowie der Datenverarbeitung anzupassen.
        </p>
      </section>
    </Layout>
  );
}

export default ContactPage;
